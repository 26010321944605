import React from "react";
import { Link } from "react-router-dom";
import LogoBVDesktop from "../../assets/logoBV.png";
import LogoBVMobile from "../../assets/logoBV Mobile.png";

function Softwareeng() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Software Engineer
        </h1>

        <h2 className="text-xl font-medium mb-4 text-center">Over Ons</h2>
        <p className="text-gray-700 mb-4">
          Bij IT Solutions Worldwide zijn we trots op onze rol als vertrouwde
          partner voor bedrijven die streven naar strategisch advies en
          innovatieve oplossingen. Met diepgaande ervaring in diverse sectoren
          levert ons team van ervaren consultants ongeëvenaarde expertise en een
          klantgerichte benadering bij elk project. We zijn gepassioneerd over
          het helpen van onze klanten om hun doelstellingen te bereiken en hun
          concurrentiepositie te versterken in een voortdurend veranderende
          markt. Sluit je aan bij ons en werk mee aan het vormgeven van
          toekomstgerichte bedrijfsoplossingen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Over de klant</h2>
        <p className="text-gray-700 mb-4">
          Voor een klant zijn we op zoek naar een software engineer. De klant is
          gevestigd in Ypenburg en heeft meer dan 150 collega’s. Ze zijn
          gedreven om hightech mechatronische systemen te ontwikkelen én
          seriematig te produceren. Dit doen ze voor de halfgeleiderindustrie,
          medische sector en laboratoriummarkt.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Functieomschrijving
        </h2>
        <p className="text-gray-700 mb-4">
          Als Software Engineer ben je verantwoordelijk voor de levenscyclus van
          software in complexe machines en modules. Dit doe je samen met
          collega's in een multidisciplinair team met sterke interactie. Je
          krijgt de kans om concepten te bedenken en te ontwikkelen, zodat het
          product met jouw software in productie kan worden genomen of gebruikt
          kan worden voor doeleinden zoals kwalificatie. Dit gebeurt in nauw
          overleg met klanten en, uiteraard, je collega's.
        </p>
        <p className="text-gray-700 mb-4">
          De ontwikkelingsafdeling, bestaande uit ongeveer 30 collega's, omvat
          ontwerpers, mechatronische ontwerpers, optische ontwerpers,
          elektronicaspecialisten, softwarespecialisten en projectleiders. Hier
          vind je jouw plek om je kennis, creativiteit en ervaring op een
          zinvolle manier te gebruiken en te delen met ons en onze klanten.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Vereisten</h2>
        <ul className="text-gray-700 list-disc mb-4 pl-5">
          <li>
            Jij krijgt energie van het ontwikkelen van software voor complexe
            machines en modules. Je bent creatief, hebt een hoog kennisniveau en
            houdt van technische uitdagingen. Daarnaast ben je geïnteresseerd in
            een optimale integratie in de verschillende multidisciplinaire
            projectteams met jouw competenties.
          </li>
          <li>
            Jij beschikt over een HBO- of WO-diploma in technische informatica,
            technische computing of elektrotechniek. Je hebt 3 tot 5 jaar
            ervaring in softwareontwikkeling.
          </li>
          <li>
            Kennis van Python, Linux/Bash/Windows, OOP, Design Patterns, REST
            APIs, Continuous Integration en GIT.
          </li>
          <li>Ervaring met EtherCAT/TwinCAT is een pluspunt.</li>
          <li>Ervaring met MATLAB/Simulink is een pluspunt.</li>
          <li>Kennis/ervaring met embedded systemen is een pluspunt.</li>
          <li>Affiniteit met Agile/Scrum is een pluspunt.</li>
          <li>
            Communicatief, assertief en bereid om vragen te stellen en met
            nieuwe ideeën te komen.
          </li>
          <li>
            Bereid om je te verdiepen in onze producten en mechatronische
            systemen.
          </li>
          <li>
            Pragmatisch en doelgericht om problemen op te lossen, vooral als
            deze zich voordoen tijdens de lopende productie.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Aanbod</h2>
        <ul className="text-gray-700 list-disc mb-4 pl-5">
          <li>
            Een uitdagende rol in een hightech omgeving met korte
            communicatielijnen.
          </li>
          <li>
            Een leuke, open werksfeer waar jouw meningen en ideeën gewaardeerd
            worden.
          </li>
          <li>
            Jouw bijdrage aan een mechatronisch ontwerp zal worden gebouwd en in
            productie worden genomen, zodat je de resultaten van je werk kunt
            zien.
          </li>
          <li>Veel verantwoordelijkheid.</li>
          <li>Opbouwen van de softwarecompetentie.</li>
          <li>
            Afwisselend en gevarieerd werk; verschillende projecten voor
            verschillende marktsegmenten.
          </li>
          <li>Marktconform salaris.</li>
          <li>Mogelijkheden voor cursussen en opleidingen.</li>
          <li>
            Arbeidsvoorwaarden volgens de CAO Metalektro, inclusief 27
            vakantiedagen en 13 ADV-dagen.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          Geïnteresseerd? Stuur je cv en motivatiebrief naar
          info@itsolutionsworldwide.com of reageer via onze website vacature.
        </p>
      </div>
    </div>
  );
}

export default Softwareeng;
