import React from "react";
import { Link } from "react-router-dom";
import LogoBVDesktop from "../../assets/logoBV.png";
import LogoBVMobile from "../../assets/logoBV Mobile.png";

function ITSupportmedewerker() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          IT Support Medewerker (Startersfunctie)
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">Over Ons</h2>
        <p className="text-gray-700 mb-4">
          Bij IT Solutions Worldwide zijn we trots op onze rol als vertrouwde
          partner voor bedrijven die streven naar strategisch advies en
          innovatieve oplossingen. Met diepgaande ervaring in diverse sectoren
          levert ons team van ervaren consultants ongeëvenaarde expertise en een
          klantgerichte benadering bij elk project. We zijn gepassioneerd over
          het helpen van onze klanten om hun doelstellingen te bereiken en hun
          concurrentiepositie te versterken in een voortdurend veranderende
          markt. Sluit je aan bij ons en werk mee aan het vormgeven van
          toekomstgerichte bedrijfsoplossingen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Over de klant:</h2>
        <p className="text-gray-700 mb-4">
          Voor een klant zijn we op zoek naar een IT Support medewerker. De
          klant is gevestigd in Ypenburg en heeft meer dan 150 collega’s. Ze
          zijn gedreven om hightech mechatronische systemen te ontwikkelen én
          seriematig te produceren. Dit doen ze voor de halfgeleiderindustrie,
          medische sector en laboratoriummarkt.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Verantwoordelijkheden
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Ondersteunen van een soepel verloop van IT-operaties in de
            toegewezen regio, inclusief kantoornetwerken, internetapparaten en
            computersystemen.
          </li>
          <li>
            Helpen bij het ontwerp, de ontwikkeling, implementatie en het
            onderhoud van de IT-infrastructuur om proactief te plannen voor
            toekomstige groei van de organisatie.
          </li>
          <li>Het bijhouden van IT apparatuur en onderdelen.</li>
          <li>
            Ondersteunen bij de aanschaf van IT-apparatuur en contact
            onderhouden met lokale leveranciers/ISP’s indien nodig voor het
            beheer van de IT-infrastructuur.
          </li>
          <li>
            Hands-on werken met het team om de continuïteit van de operaties te
            waarborgen.
          </li>
          <li>
            Helpen bij het tijdig oplossen van IT-ondersteuningstickets die door
            medewerkers van het bedrijf worden ingediend.
          </li>
          <li>
            Een scherp oog hebben voor informatiebeveiliging binnen de
            organisatie en informatiebeveiligingsbeleid implementeren.
          </li>
          <li>Ondersteunen bij applicatiebeheer (o.a. ERP systemen).</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Vereisten</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Inzicht in besturingssystemen (Windows, macOS, Linux).</li>
          <li>
            Basiskennis van computerhardwarecomponenten en randapparatuur.
          </li>
          <li>Basiskennis van remote desktop-software en -tools.</li>
          <li>
            Vermogen om veelvoorkomende hardware- en softwareproblemen te
            diagnosticeren en op te lossen.
          </li>
          <li>
            Kennis van veelvoorkomende probleemoplossingstechnieken en -tools.
          </li>
          <li>Basic kennis van endpoint security tools.</li>
          <li>MBO 4 of HBO opleiding in een gerelateerd vakgebied.</li>
          <li>Werkervaring: 0 tot 2 jaar.</li>
        </ul>
      </div>
    </div>
  );
}

export default ITSupportmedewerker;
