import React from "react";
import { Link } from "react-router-dom";
import LogoBVDesktop from "../../assets/logoBV.png";
import LogoBVMobile from "../../assets/logoBV Mobile.png";
function ProjectMag() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Project manager
        </h1>

        <h2 className="text-xl font-medium mb-4 text-center">Over Ons</h2>
        <p className="text-gray-700 mb-4">
          Bij IT Solutions Worldwide zijn we trots op onze rol als vertrouwde
          partner voor bedrijven die streven naar strategisch advies en
          innovatieve oplossingen. Met diepgaande ervaring in diverse sectoren
          levert ons team van ervaren consultants ongeëvenaarde expertise en een
          klantgerichte benadering bij elk project. We zijn gepassioneerd over
          het helpen van onze klanten om hun doelstellingen te bereiken en hun
          concurrentiepositie te versterken in een voortdurend veranderende
          markt. Sluit je aan bij ons en werk mee aan het vormgeven van
          toekomstgerichte bedrijfsoplossingen.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">Over de klant:</h2>
        <p className="text-gray-700 mb-4">
          Voor een klant zijn we op zoek naar een project manager. De klant is
          gevestigd in Ypenburg en heeft meer dan 150 collega’s. Ze zijn
          gedreven om hightech mechatronische systemen te ontwikkelen én
          seriematig te produceren. Dit doen ze voor de halfgeleiderindustrie,
          medische sector en laboratoriummarkt.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Functieomschrijving
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Jij bent verantwoordelijk voor het projectmanagement voor
            ontwerpers, planners, inkopers, technici en technische
            productmanagers. Je zorgt ervoor dat zij goed geïnformeerd zijn en
            je stuurt het team aan om projecten succesvol af te ronden.
          </li>
          <li>
            Jij houdt toezicht op de verschillende projecten onder jouw
            verantwoordelijkheid, inclusief het beheren van de noodzakelijke
            acties.
          </li>
          <li>
            Jij fungeert als het primaire aanspreekpunt voor de klant, begrijpt
            hun behoeften om een effectief actieplan en schema te ontwikkelen.
          </li>
          <li>
            Bovenop het beheren van tijd en budget heb je ook een goed begrip
            van technologie om je team uit te dagen en bij te dragen aan
            besluitvorming en probleemoplossing.
          </li>
          <li>
            Jij identificeert projectrisico's en implementeert maatregelen om
            deze te mitigeren. Je bewaakt deze risico's en maakt indien nodig
            aanpassingen.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Vereisten</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Jij hebt een passie voor technologie.</li>
          <li>
            Effektieve communicatie met zowel de klant als je team is cruciaal.
            Je kunt je richten op de hoofdzaken, terwijl je ook aandacht hebt
            voor details. Je manageert effectief.
          </li>
          <li>
            Jij hebt een academische graad in een technisch vakgebied, aangevuld
            met enkele jaren ervaring in projectmanagement bij de ontwikkeling
            van complexe systemen. Ervaring met medische projecten is een
            voordeel.
          </li>
          <li>Jij spreekt en schrijft vloeiend Nederlands en Engels.</li>
          <li>Jij bent beschikbaar voor minimaal 32 tot 40 uur per week.</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Aanbod</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Een uitdagende functie met ruimte voor persoonlijke initiatief en
            ontwikkeling.
          </li>
          <li>
            Samenwerking in een dynamisch team binnen een snelgroeiende
            organisatie.
          </li>
          <li>Een salaris dat overeenkomt met jouw kennis en ervaring.</li>
          <li>De mogelijkheid tot hybride werken.</li>
          <li>Laptop en telefoon.</li>
          <li>Een kwartaalresultaatregeling.</li>
          <li>Opleidingsmogelijkheden binnen en buiten onze organisatie.</li>
          <li>
            Collectieve Arbeidsovereenkomst Metalektro, inclusief 27
            vakantiedagen en 13 ADV-dagen.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          Geïnteresseerd? Stuur je cv en motivatiebrief naar
          info@itsolutionsworldwide.com of reageer via onze website vacature.
        </p>
      </div>
    </div>
  );
}

export default ProjectMag;
